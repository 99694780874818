
import {
    defineComponent, ref, reactive
} from 'vue';
import {
    list, ListHeaderItem
} from '@/components/common/list';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import chooseBuildRoomInit from '@/methods/building-apt-choose';
import { access } from '@/data';
import { overlayFormData } from '@/util/system';
import { getListForPmDevice } from '@/api/community/device';
import SetThirdDeviceDialog, { FormData } from './set-control-device-dialog.vue';

interface SearchList {
    label: string;
    value: string;
}
const statusSearchList: Array<SearchList> = [
    {
        value: 'all',
        label: WordList.SelectAll
    },
    {
        value: '1',
        label: WordList.SelectOnline
    },
    {
        value: '0',
        label: WordList.SelectOffline
    }
];
// 输入框下拉选项
const SearchKeyList: Array<SearchList> = [
    { label: WordList.TabelPersonDeviceInHtmlMAC, value: 'MAC' },
    { label: WordList.TabelPersonDeviceInHtmlLocation, value: 'Location' }
];
// 表格列表
const headers: Array<ListHeaderItem> = [
    {
        name: 'UnitName',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'RoomName',
        label: WordList.RDeviceSearchLabelRoomName
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'LastConnection',
        label: WordList.ProperAllTextBindTime
    }
];
export default defineComponent({
    components: {
        list,
        SetThirdDeviceDialog,
        secondaryNav
    },
    setup() {
        const updateData = ref(0);
        // 搜索条件
        const formData = reactive({
            Build: 'all',
            Room: 'all',
            Status: 'all',
            Type: 'all',
            searchKey: 'MAC',
            searchValue: '',
            Brand: 'all',
            row: 10,
            page: 1
        });
        overlayFormData(formData);

        // 表格数据获取
        const tableData = reactive<{row: object[];detail: object[];total: string}>({
            row: [],
            detail: [],
            total: '0'
        });
        // 获取第三方设备数据
        const getThirdPartyDeviceApi = () => {
            getListForPmDevice(formData, (res: {row: object[];detail: object[];total: string}) => {
                tableData.row = res.row;
                tableData.detail = res.detail;
                tableData.total = res.total;
                updateData.value += 1;
            });
        };
        getThirdPartyDeviceApi();

        // 子标签
        const secondMenus = [{
            label: WordList.ControlPanel,
            key: 'control',
            path: ''
        }];
        const activeNavItem = ref('control');
        const changePath = (key: string) => {
            activeNavItem.value = key;
        };

        // 获取build、apt下拉框选项
        const { buildOptions, aptOptions, setRoomOption } = chooseBuildRoomInit();

        // 控制是否显示编辑设备弹窗
        const isShowEditDevice = ref(false);
        const initForm: FormData = reactive({
            ID: '',
            Location: '',
            UnitName: '',
            RoomName: ''
        });
        // 编辑设备
        function edit(item: FormData) {
            initForm.ID = item.ID;
            initForm.Location = item.Location;
            initForm.UnitName = item.UnitName;
            initForm.RoomName = item.RoomName;
            isShowEditDevice.value = true;
        }

        return {
            secondMenus,
            activeNavItem,
            changePath,
            buildOptions,
            aptOptions,
            setRoomOption,
            statusSearchList,
            SearchKeyList,
            headers,
            isShowEditDevice,
            initForm,
            edit,
            access,
            formData,
            updateData,
            tableData,
            getThirdPartyDeviceApi
        };
    }
});
